import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from 'google-login-react';
import BASE_URLS from '../baseUrl';

function SignupModal({showSignup, onHide, openLoginModal, openSigninModal, handleGoogleLoginFailure, handleGoogleLoginSuccess}) {
  const webUrl = BASE_URLS.webUrl;
  return (
    <>
    <Modal show={showSignup}
        onHide={onHide} size="md" centered>
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body className='text-center p-5 pt-0'>
        <h4 className='font-weight-bold'>Sign up</h4>
        <p>to unlock the full experience</p>
        <div className='d-flex justify-content-around mt-3'>
          <div className='signip-items'>
            <i className='bi bi-gift'></i>
            <p className='small font-weight-bold'>10% off your first booking</p>
          </div>
          <div className='signip-items'>
            <p className='small font-weight-bold'></p>
          </div>
          <div className='signip-items'>
            <i className='bi bi-heart'></i>
            <p className='small font-weight-bold'>Add to your wishlist</p>
          </div>
        </div>

        <GoogleLogin
          clientId="69923402974-r6v71a76q6m149th2ut2v02ms67cpeh3.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={handleGoogleLoginSuccess}
          onFailure={handleGoogleLoginFailure}
          cookiePolicy={'single_host_origin'}
          render={(renderProps) => (
            <span className='signup-buttons'>
            <Button
              variant="outline-dark"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className='mt-5'
            >
              <img
                src="https://developers.google.com/identity/images/g-logo.png"
                alt="Google"
                width="18"
                height="auto"
                className='mr-1'
                loading="lazy"
              />
              Continue with Google
            </Button>
            </span>
          )}
        />

        <div className='mt-3 signup-buttons'>
          <Button variant="outline-dark" onClick={() => { onHide(); openSigninModal(); }} >Continue with email</Button>
        </div>

          <p className='pt-3 small'>By signing up, you agree to Rathin's <Link to={`${webUrl}terms-of-use`}>Terms of Use</Link> and for your personal data to be processed in accordance with our <Link to={`${webUrl}privacy-policy`}>Privacy Policy</Link>.</p>
          <h6 className='font-weight-bold pt-4'>
          Already have an account?{' '}
          <Link onClick={() => { onHide(); openLoginModal(); }} >Log in</Link>
        </h6>
      </Modal.Body>
    </Modal>
    </>
  );
}

export default SignupModal;
