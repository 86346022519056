import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { ContactInfo } from './ContactInfo'
import { ContactForm } from './ContactForm'
import { Skeleton } from '@mui/material';
import BASE_URLS from '../../baseUrl';

export const ContactDetails = () => {
    const webUrl = BASE_URLS.main;
    const [addr, setAddr] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getCurrAddr = async () => {
            try {
                const res = await fetch(`${webUrl}/get_logos.php`);
                const data = await res.json();
                setAddr(data.cont_cont);
            } catch (error) {
                console.error('Error fetching contact details:', error);
            } finally {
                setLoading(false);
            }
        };
        getCurrAddr();
    }, [webUrl]);

    return (
        <section className='contact-us'>
            <Container>
                <div className="section-header">
                    <p>Contact <span>Us</span></p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    {loading ? (
                        <Skeleton animation="wave" variant="rounded" width={200} height={20} />
                    ) : (

                        <p className='text-center'>{addr}</p>
                    )}
                </div>
                <Row className='gx-lg-0 gy-4 pt-4'>
                    <Col lg={4} sm={4} md={5}>
                        <ContactInfo />
                    </Col>

                    <Col lg={8} sm={8} md={7}>
                        <ContactForm />
                    </Col>
                </Row>

            </Container>
        </section>
    )
}
