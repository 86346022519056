import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URLS from '../baseUrl';

// Import the 404 component
import { NotFound } from './NotFound';

export const ResetPassword = () => {
    const { token } = useParams();
    // Extracting the token without the prefix
    const tokenValue = token.split('=')[1];

    const webUrl = BASE_URLS.webUrl;
    const navigate = useNavigate();
    const [tokenNotFound, setTokenNotFound] = useState(false); // New state to track token existence

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handlePasswordReset = async (e) => {
        e.preventDefault();

            // Regular expressions for uppercase and special character
        const uppercaseRegex = /[A-Z]/;
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }
        // Check if passwords meet length requirements
        if (password.length < 8 || confirmPassword.length < 8) {
            setErrorMessage('Password must be at least 8 characters long');
            return;
        }

        // Check if passwords contain at least one uppercase letter
        if (!uppercaseRegex.test(password) || !uppercaseRegex.test(confirmPassword)) {
            setErrorMessage('Password must contain at least one uppercase letter');
            return;
        }

        // Check if passwords contain at least one special character
        if (!specialCharacterRegex.test(password) || !specialCharacterRegex.test(confirmPassword)) {
            setErrorMessage('Password must contain at least one special character');
            return;
        }

        try {
            const response = await fetch(`${BASE_URLS.main}/reset_password.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tokenValue, password }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.result === 'Success') {
                    toast.success('Your password updated successfully.', {
                        autoClose: 5000,
                    });
                    navigate(webUrl);
                } else {
                    setErrorMessage('Failed to update password.');
                }
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to update password. Please try again.');
        }
    };

    useEffect(() => {
        // Check if the token exists
        const checkTokenExists = async () => {
            try {
                const response = await fetch(`${BASE_URLS.main}/check_token.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ tokenValue }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.result === 'Token not found') {
                        setTokenNotFound(true);
                    }
                } else {
                    throw new Error('Network response was not ok.');
                }
            } catch (error) {
                console.error('Error:', error);
                setErrorMessage('Failed to check token. Please try again.');
            }
        };

        checkTokenExists();
    }, [tokenValue]);

    if (tokenNotFound) {
        return <NotFound />;
    }

    return (
        <div className='body-content'>
            <Helmet>
                <title>Reset Password | Rathin Tourism LLC</title>
            </Helmet>
            <div className="breadcrumbs">
                <Container>
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li>
                                <Link to={webUrl}>Home</Link>
                            </li>
                            <li>Reset Password</li>
                        </ol>
                    </div>
                </Container>
            </div>
            <section>
                <Container>
                    <Row className="text-center">
                        <Col md={{ span: 4, offset: 4 }}>
                            <h4 className="font-weight-bold">Reset Your Password</h4>
                            <p>Enter your new password below to reset your account password.</p>
                            <form  onSubmit={handlePasswordReset} className="Login-form">
                                <div className="form-group mt-3">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        minLength={8}
                                        maxLength={16}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-3 mb-3">
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        minLength={8}
                                        maxLength={16}
                                        required
                                    />
                                </div>
                                <Button type="submit" name='reset_password' variant="primary">
                                    Reset Password
                                </Button>
                                {errorMessage && <p className="text-danger pt-3">{errorMessage}</p>}
                            </form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default ResetPassword;
