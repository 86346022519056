import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Skeleton } from '@mui/material'; // Import Skeleton from MUI
import BASE_URLS from '../../baseUrl';


export const AdditionalInfo = ({ proData }) => {
    const [resultProdAdd, setTotProdAdd] = useState([]);
    const [resultProdFaq, setTotProdFaq] = useState([]);
    const [loadingProdAdd, setLoadingProdAdd] = useState(true);
    const [loadingProdFaq, setLoadingProdFaq] = useState(true);
    const mainApiUrl = BASE_URLS.main;

    // useEffect(() => {
    // if (proData.content3) {
    //     // setTotProdAdd(proData.content3);
    //     // setLoadingProdAdd(false);
    // }
    // if (proData.arr_faq) {
    //     setTotProdFaq(proData.arr_faq);
    //     setLoadingProdFaq(false);
    // }
    // }, [proData]);

    useEffect(() => {
        const getCurrAddr = async () => {
            if (proData.product_slug) {
                try {
                    const res = await fetch(`${mainApiUrl}/get_products_detail_content.php?product_slug=${proData.product_slug}`);
                    const data = await res.json();
                    setTotProdAdd(data.content3);
                    setTotProdFaq(data.arr_faq);
                } catch (error) {
                    console.error('Error fetching contact details:', error);
                } finally {
                    setLoadingProdAdd(false);
                    setLoadingProdFaq(false);
                }
            }
        };
        getCurrAddr();
    }, [mainApiUrl, proData]);

    return (
        <>
            <div className="additional-info" data-aos="fade-up" data-aos-delay="200">
                {loadingProdAdd ? ( // Skeleton for additional info section
                    <>
                        <Skeleton animation="wave" variant="text" width="100%" height={40} className="mb-2" />
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                        <Skeleton animation="wave" variant="text" width="100%" height={60} className="mb-2" />
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                        <Skeleton animation="wave" variant="text" width="100%" height={60} />
                    </>
                ) : (
                    <>
                        {resultProdAdd.length > 0 && (
                            <h3 className=" mt-5 mb-4">Additional Information</h3>
                        )}
                        <Accordion defaultActiveKey="0">
                            {resultProdAdd.map((item, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>{item.product_head}</Accordion.Header>
                                    <Accordion.Body>
                                        <span dangerouslySetInnerHTML={{ __html: item.product_content }}></span>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </>
                )}
            </div>
            <div className="faq-info" data-aos="fade-up" data-aos-delay="200">
                {loadingProdFaq ? ( // Skeleton for FAQ section
                    <>
                        <Skeleton animation="wave" variant="text" width="100%" height={40} className="mb-2" />
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                        <Skeleton animation="wave" variant="text" width="100%" height={60} className="mb-2" />
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                        <Skeleton animation="wave" variant="text" width="100%" height={60} />
                    </>
                ) : (
                    <>
                        {resultProdFaq.length > 0 && (
                            <h3 className=" mt-5 mb-4">FAQ</h3>
                        )}
                        <Accordion defaultActiveKey="0">
                            {resultProdFaq.map((item, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>{item.faq_que}</Accordion.Header>
                                    <Accordion.Body>
                                        <span>{item.faq_ans}</span>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </>
                )}
            </div>
        </>
    );
};
