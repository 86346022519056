import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import success from "../../Images/payment-success.svg";
import { Skeleton } from "@mui/material";

export const BookingSuccess = () => {
  const webUrl = BASE_URLS.webUrl;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [tick_last_data, setTickLastData] = useState(null);
  const [tick_full_last_cama, setTickFullLastCama] = useState(null);
  const [paid_amount, setPaidAmount] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const state = location.state;
        if (state) {
          const orderDetailsData = state.orderDetails.order_details[0];
          const tickLastData = state.orderDetails.tick_last_data;
          const tickFullLastCama = state.orderDetails.tick_full_last_cama;
          const paidamount = state.orderDetails.paid_amount;
          setPaidAmount(paidamount);
          setOrderDetails(orderDetailsData);
          setTickLastData(tickLastData);
          setTickFullLastCama(tickFullLastCama);
          if(orderDetails.length > 1){
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [location.state]);

  if (loading) {
    // Return skeleton loading UI
    return (
      <>
        <div className="breadcrumbs">
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <Link to={webUrl}>Home</Link>
                </li>
                <li>Payment Success</li>
              </ol>
            </div>
          </Container>
        </div>
        <section className="payments">
          <Container>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <center>
                  <Skeleton animation="wave" className="text-center mb-3" variant="rounded" width="40%" height={300} />
                  <Skeleton animation="wave" className="text-center mb-3" variant="text" width="40%" height={40} />
                </center>
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton key={index} animation="wave" variant="text" width="100%" height={30} />
                ))}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }

  return (
    <div className="body-content">
      <Helmet>
        <title>Payment Success | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Payment Success</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="payments">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="booking success">
                <div className="booking-header">
                  <div className="bheader">
                    <img
                      src={success}
                      width="250px"
                      height="auto"
                      className="img-fluid pt-3 pb-3"
                      alt="Booking Success"
                      loading="lazy"
                    />
                  </div>
                </div>
                <h3 className="font-weight-bold  mt-3">Payment Successful!</h3>
                <p>
                  Congratulations, your payment has been successfully processed.
                  Thank you for booking with us!
                </p>
              </div>
              <div className="mt-4">
                <h6 className="font-weight-bold">
                  Here's the summary of your booking
                </h6>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Order ID</th>
                        <td>{orderDetails.order_no}</td>
                      </tr>
                      <tr>
                        <th>Guest name</th>
                        <td>{orderDetails.full_name}</td>
                      </tr>
                      <tr>
                        <th>Product name</th>
                        <td>{orderDetails.product_name}</td>
                      </tr>
                      <tr>
                        <th>Package name</th>
                        <td>{tick_last_data} </td>
                      </tr>
                      <tr>
                        <th>Preferences</th>
                        <td>{tick_full_last_cama} </td>
                      </tr>
                      <tr>
                        <th>Paid Amount</th>
                        <td>{paid_amount} AED</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h6 className="font-weight-bold mt-3">
                  {" "}
                  Important Information :
                </h6>
                <ul>
                  <li>
                    You will receive a confirmation email shortly with all the
                    details of your booking
                  </li>
                  <li>
                    Please retain your Order ID for any future reference or
                    inquiries.
                  </li>
                </ul>
                <p>
                  If you have any questions or need further assistance, feel
                  free to contact our support team at{" "}
                  <a
                    href="mailto:info@rathin.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@rathin.com
                  </a>{" "}
                  or call us at{" "}
                  <a href="tel:+971 56 537 4934">+971 56 537 4934</a>.
                </p>
                <p>
                  We hope you have a fantastic experience on your{" "}
                  <b>{orderDetails.product_name}</b>. Thank you for
                  choosing us!
                </p>
                <p>Enjoy your journey!</p>
                <center className="mt-5">
                  <Link to={webUrl}>
                    <Button variant="primary">Back to Home</Button>
                  </Link>
                </center>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
