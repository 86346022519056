import React, { useState, useEffect } from "react";
import BASE_URLS from '../../baseUrl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from MUI
import "bootstrap-icons/font/bootstrap-icons.css";

function Highlights() {
	const [infoItems, setInfoItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const mainApiUrl = BASE_URLS.main;
  
	useEffect(() => {
	  const fetchData = async () => {
		try {
		  const response = await fetch(`${mainApiUrl}/get_footer_highlights.php`);
		  const data = await response.json();
  
		  if (data.status === "1") {
			setInfoItems(data.high_list);
		  } else {
			console.error("Failed to fetch data:", data.result);
		  }
		} catch (error) {
		  console.error("Error fetching data:", error.message);
		} finally {
		  setIsLoading(false);
		}
	  };
  
	  fetchData();
	}, [mainApiUrl]);

	return (
		<section className='section-bg'>
			<Container>
				<div className="section-header">
					<p>Why Rathin <span>Tourism?</span></p>
				</div>
				<Row className="gy-3">
					{isLoading ? (
						Array.from({ length: 4 }).map((_, index) => (
							<Col key={index} lg={3} md={6} sm={12}>
								<Skeleton animation="wave" variant="text" width="100%" height={155}  />
							</Col>
						))
					) : (
						infoItems.map((highlight, index) => (
							<Col key={index} lg={3} md={6} sm={12}>
								<div className="icon-box d-grid">
									<i className={highlight.cont_icon}></i>
									<span>
										<h4>{highlight.cont_title}</h4>
										<p>{highlight.cont_desc}</p>
									</span>
								</div>
							</Col>
						))
					)}
				</Row>
			</Container>
		</section>
	);
}

export default Highlights;
