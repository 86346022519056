import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

function Activities() {
  const navigate = useNavigate();
  const mainApiUrl = BASE_URLS.main;
  const [actData, setActivities] = useState([]);
  const webUrl = BASE_URLS.webUrl;
  const [loading, setLoading] = useState(true); // Loading state
  useEffect(() => {
    const getActivities = async () => {
      const res = await fetch(`${mainApiUrl}/get_activities.php`);
      const getdata = await res.json();
      setActivities(getdata.act_arr);
      setLoading(false);
    };
    getActivities();
  }, [mainApiUrl]);

  const handleButtonClick = (item) => {
    navigate(`${webUrl}activity-list`, {
      state: { activities_id: item.act_id },
    });
  };

  return (
    <section className="icon-section section-bg">
      <Container>
        <div className="section-header">
          <p>
            More <span>Activities</span>
          </p>
        </div>
        <Row className="gy-4">
          <div className="icon-row gap-3">
          {loading ? ( // Show skeleton if loading
              <>
                {[...Array(9)].map((_, index) => ( // Adjust the number of skeleton items
                  <Skeleton key={index} className="icon-box" animation="wave" variant="rounded" width="100%" height={120} />
                ))}
              </>
            ) : (
            // Render actual content once data is loaded
            actData.map((item, index) => (
              <button
                key={index}
                onClick={() => handleButtonClick(item)}
                className="icon-box"
              >
                <img className="img-fluid" src={item.act_image} alt="" loading="lazy" width="auto" height="50px"/>
                <p>{item.act_name}</p>
              </button>
              ))
            )}
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Activities;
