import React from "react";
import { useFilter } from "../../Contexts/filterContext";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

export const TopFilter = ({ actList }) => {
  const { handleFilters, act_value_list } = useFilter();
  const location = useLocation();
  const activity_id = location.state && location.state.activities_id;

  // Placeholder skeleton
  const skeletonFilter = (
    <div className="row">
      <div className="col-md-12 mobile-none">
        <div className="search-list-icons gap-3">
          {[...Array(10)].map((_, index) => (
            <div className="search-icons" key={index}>
              <center>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" width={100} height={40} />
              </center>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="row">
      <div className="col-md-12 mobile-none">
        <p>
          <span className="font-weight-bold">Traveller & Activity types</span>{" "}
          <span className="small">Select one or more</span>
        </p>
        {actList.length > 0 ? ( // Render actual content if actList has data
          <div className="search-list-icons">
            {actList.map((item, index) => (
              <div className="search-icons" key={index}>
                <input
                  type="checkbox"
                  className="btn-check"
                  id={"fq2" + item.act_id}
                  autoComplete="off"
                  onClick={(event) =>
                    handleFilters(
                      "",
                      "",
                      "",
                      "",
                      "",
                      item.act_id,
                      null,
                      null,
                      null
                    )
                  }
                  checked={act_value_list.includes(item.act_id)}
                />
                <label className="btn btn-primary" htmlFor={"fq2" + item.act_id}>
                  <i className={item.act_icon}></i>
                  {item.act_name}
                </label>
              </div>
            ))}
          </div>
        ) : (
          // Render skeleton if actList is empty or loading
          skeletonFilter
        )}
      </div>
    </div>
  );
};
