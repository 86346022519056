import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from '../baseUrl';

function Promotion() {
    const webUrl = BASE_URLS.webUrl;
  return (
    <div className='body-content'>
        <Helmet>
            <title>Promotions | Rathin Tourism LLC</title>
        </Helmet>
        <div className="breadcrumbs">
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                <ol>
                    <li><Link to={webUrl}>Home</Link></li>
                    <li>Promotions</li>
                </ol>
                </div>
            </Container>
        </div>
        <section className="terms-conditions">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div className="section-header">
                            <p className="text-left">Terms & Conditions for <span>Rathin Tours Promotions</span></p>
                        </div>
                        <h6 className="font-weight-bold">RATHINWELCOME10 Promotion:</h6>
                        <ol>
                            <li>This promotion (<b>“Promotion”</b>) is organised by Encounters Pte Ltd (<b>“Rathin Tours”</b>) and is valid for bookings made between <b>06 April 2021</b> (16:00 Singapore time) and <b>31 Dec 2023</b> (23:59 Singapore time) (<b>“Promotion Period”</b>).</li>
                            <li>During this Promotion Period, you can enter the promo code <b>"RATHINWELCOME10"</b> during checkout and prior to payment, in order to qualify for a <b>10%</b> discount off your total booking.</li>
                            <li>This Promotion is subject to the following restrictions:
                                <ul>
                                    <li>Discount value is capped at <b>SGD 10.00</b>, or currency equivalent.</li>
                                    <li>Only valid for use once per new user.</li>
                                    <li>Only valid for new users.</li>
                                    <li>All promotions are subject to product exclusions, which will be updated from time to time at Rathin Tours's sole discretion.</li>
                                </ul>
                            </li>
                            <li>These Promotions cannot be applied together or with any other existing Rathin Tours promotions, offers or privileges, unless otherwise stated.</li>
                            <li>All bookings are subject to Rathin Tours’s Terms of Use, and all collection, use and disclosure of personal data submitted for purchases made during this Promotion will be in accordance with Rathin Tours’s Privacy Policy.</li>
                            <li>Rathin Tours reserves the right to amend or withdraw any terms and conditions of this Promotion, and/or terminate the Promotion, without any prior notice at its sole and absolute discretion.</li>
                            <li>The terms and conditions of this Promotion shall be governed by and construed in accordance with the laws of the Republic of Singapore and shall be subject to the exclusive jurisdiction of the Singapore courts.</li>
                        </ol>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
  );
}
export default Promotion;