import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CheckoutSidebar } from "./CheckoutSidebar";
import { CheckoutAddonSidebar } from "./CheckoutAddonSidebar";
import { CheckoutForm } from "./CheckoutForm";
import BASE_URLS from "../../baseUrl";

function CheckOut() {
  const webUrl = BASE_URLS.webUrl;
  const location = useLocation();
  const state = location.state;
  const bookingDetails = state ? state.bookingDetails : null;
  const [discountPercent, setDiscountPercent] = useState(0);
  const [bookingDetails_addon, setBookingDetailsAddon] = useState([]);

  const totalAfterDiscount = (Amount, percentage) => {
    return Amount - (Amount * percentage) / 100;
  };

  return (
    <div className="body-content">
      <Helmet>
        <title>Checkout | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Secure Checkout</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="checkout">
        <Container>
          <Row className="gy-4">
            <Col
              xs={12}
              lg={7}
              md={7}
              className="order-lg-1 order-md-1 order-sm-2"
            >
              <CheckoutForm
                bookingDetails={bookingDetails}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                totalAfterDiscount={totalAfterDiscount}
                bookingDetails_addon={bookingDetails_addon}
              />
            </Col>
            <Col
              xs={12}
              lg={5}
              md={5}
              className="order-lg-2 order-md-2 order-sm-1"
            >
              <CheckoutSidebar
                bookingDetails_addon={bookingDetails_addon}
                bookingDetails={bookingDetails}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                totalAfterDiscount={totalAfterDiscount}
              />

              <CheckoutAddonSidebar
                bookingDetails={bookingDetails}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                totalAfterDiscount={totalAfterDiscount}
                bookingDetails_addon={bookingDetails_addon}
                setBookingDetailsAddon={setBookingDetailsAddon}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
export default CheckOut;
