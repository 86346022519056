import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import BASE_URLS from "../../baseUrl";
import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import { useCurr } from "../../Contexts/currContext";
import { EmptyActivity } from "./EmptyActivity";
import { Skeleton } from "@mui/material";

export const ListActivity = ({ resultProd }) => {
  const webUrl = BASE_URLS.webUrl;
  const itemsPerPage = 9; // Number of items to display per page
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [loading, setLoading] = useState(true);

  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage); // Increase by itemsPerPage
  };

  useEffect(() => {
    // Set default visible items to itemsPerPage when resultProd changes
    if (resultProd.length > 0) {
      setVisibleItems(itemsPerPage);
      setLoading(false);
    }
  }, [resultProd, itemsPerPage]);

  const { wishlistItems, tourListWishlist, setTourListWishlist } = useWishlist();
  const { selectedCurrency, currConvert } = useCurr();

  useEffect(() => {
    if (wishlistItems && resultProd?.length > 0) {
      const initialWishlistStatus = {};
      resultProd?.forEach((product) => {
        initialWishlistStatus[product.product_id] = wishlistItems.some(
          (item) => item.product_id === product.product_id
        );
      });
      setTourListWishlist(initialWishlistStatus);
    }
  }, [wishlistItems, resultProd]);

  return (
    <div className="col-md-8 col-lg-9 search-list">
      {loading ? (
        <Row className="gy-4">
          {[...Array(itemsPerPage)].map((_, index) => (
            <Col md={6} lg={4} xs={6} key={index}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={180} className="mb-1" />
              <Skeleton animation="wave" variant="text" width="60%" height={20} className="mb-1" />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <div className="d-flex flex-row justify-content-between">
                <Skeleton animation="wave" variant="text" width="20%" height={50} />
                <Skeleton animation="wave" variant="text" width="20%" height={50} />
              </div>
            </Col>
          ))}
        </Row>
      ) : resultProd.length > 0 ? (
        <Row className="gy-4">
          {resultProd.slice(0, visibleItems).map((item, index) => (
            <Col md={6} lg={4} xs={6} key={index}>
              <div className="wsk-cp-product">
                {/* {item.dis_availablity === "Available" ? ( */}
                <div>
                  <Link to={`${webUrl}activity/${item.product_slug}`}>
                    <div className="wsk-cp-img">
                      <img loading="lazy" src={item.product_image} alt={item.product_name} className="img-fluid" width="100%"
                        height="auto" />
                      <div className="add-cart">
                        <WishlistButton heartToggle={tourListWishlist[item.product_id]} product={item.product_id} />
                      </div>
                    </div>
                    <div className="wsk-cp-text">
                      <div className="title-product">
                        <h3>{item.product_name}</h3>
                      </div>

                      <div className="description-prod">
                        {(item.availablity) == "Not Available" ? (
                          <p className="text-danger">{item.availablity}</p>
                        ) : <p className="text-success">{item.availablity}</p>}

                      </div>
                      <div className="card-footer">
                        <div className="wcf-left">
                          from{""}
                          <span className="wcf-left">
                            {parseFloat(item.product_price_starts) < parseFloat(item.tick_original_price) ? (
                              <div className="containers pb-2">
                                <s>
                                  <span className="currency">{selectedCurrency}</span>{" "}
                                  {currConvert(item.tick_original_price)}
                                </s>

                              </div>
                            ) : null}

                          </span>
                          <span className="price">
                            <span className="currency">{selectedCurrency}</span>{" "}
                            <p className="fw-bold fs-6 d-flex align-items-center justify-content-between">
                              {currConvert(item.product_price_starts)}{parseFloat(item.product_price_starts) < parseFloat(item.tick_original_price) ? (<span className="hot_badge">{item.tick_discount} </span>) : null}
                            </p>
                          </span>
                        </div>
                        <div className="wcf-right">
                          <p>
                            <i className="fa fa-star"></i> {item.product_rating}
                          </p>
                        </div>
                      </div>

                    </div>
                  </Link>
                </div>
                {/* ) : */}
                {/* <div>
                    <div className="wsk-cp-img">
                      <img loading="lazy" src={item.product_image} alt={item.product_name} className="img-fluid" width="100%" height="auto" />
                      <div className="add-cart">
                        <WishlistButton heartToggle={tourListWishlist[item.product_id]} product={item.product_id} />
                      </div>
                    </div>
                    <div className="wsk-cp-text">
                      <div className="title-product">
                        <h3>{item.product_name}</h3>
                      </div>
                      <div className="description-prod">
                        <p className="text-danger">{item.dis_availablity}</p>
                      </div>
                    </div>
                  </div> */}
                {/* } */}
              </div>
            </Col>
          ))}
          <Col md={12}>
            {visibleItems < resultProd.length && (
              <div className="text-center mt-4">
                <button className="btn btn-primary" onClick={loadMore}>
                  Load More
                </button>
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <EmptyActivity />
      )}
    </div>
  );
};
