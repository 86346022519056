import React, { useState, useEffect } from "react";

export const TimeSlot = ({ arrSessList = [], setSelectedTimeSlot, selectedTimeSlot }) => {

    const setSelectedTimeSlotFun = (data) => {
        setSelectedTimeSlot(data);
    };

    return (
        <div className='time-slot pt-4'>
            {arrSessList.length > 0 ? (
                <div className='quantity-details'>
                    <h5>Time Slots</h5>
                </div>
            ) : null}

            <div className="input-radio-date">
                {arrSessList.map(
                    (sessitem, index) =>
                        <span key={index}>
                            <input
                                type="radio"
                                className="btn-check"
                                name={sessitem.tick_sess_name}
                                value={sessitem.tick_sche_time_vals}
                                id={sessitem.tick_sche_time_id}
                                autoComplete="off"
                                defaultChecked={true}
                                onClick={() => setSelectedTimeSlotFun(sessitem.tick_sche_time_vals)}
                            />

                            <label className="btn btn-secondary" htmlFor={sessitem.tick_sche_time_id}>
                                <span className="time">{sessitem.tick_sess_time}</span>
                            </label>
                        </span>
                )}
            </div>
        </div>
    )
}

