import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from '../../baseUrl';
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from MUI

function AboutHighlights() {
  const webUrl = BASE_URLS.main;
  const [expect_data_dts, set_expect_data_dts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${webUrl}/get_footer_useful_links.php?mode=about_us`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        set_expect_data_dts(data.expect_data_dts);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false); // Update isLoading state after fetching data
      }
    };

    fetchData();
  }, [webUrl]);

  // Hide the entire section if prodData length is zero
  if (expect_data_dts && expect_data_dts.length === 0 && !isLoading) {
    return null;
  }

  return (
    <section className='section-bg'>
      <Container>
        <div className="section-header">
          <p>What can you <span>expect</span></p>
        </div>
        {isLoading ? (
          <Row className="gy-3">
            {Array.from({ length: 4 }).map((_, index) => (
              <Col key={index} lg={3} md={6} sm={12}>
                <div className="d-flex gap-1">
                  <Skeleton animation="wave" variant="rounded" width="100%" height={156}  />
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="gy-3">
            {expect_data_dts.map((high) => (
              <Col lg={3} md={6} sm={12} key={high.expect_id}>
                <div className="icon-box d-grid">
                  <i className={high.expect_icon}></i>
                  <span>
                    <h4>{high.expect_title}</h4>
                    <p>{high.expect_desc}</p>
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  )
}

export default AboutHighlights;
