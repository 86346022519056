import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { WishlistProducts } from './WishlistProducts';
import EmptyWishlist from './EmptyWishlist';
import BASE_URLS from "../../baseUrl";
import { useWishlist } from "../../Contexts/WishlistContext";
import Skeleton from '@mui/material/Skeleton';
import { Row, Col } from 'react-bootstrap';

export const Wishlist = () => {
  const { wishlistItems, getWishlistItems } = useWishlist();
  const [loading, setLoading] = useState(true);
  const isWishlistEmpty = wishlistItems.length === 0;

  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getWishlistItems();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [mainApiUrl, getWishlistItems]);

  return (
    <div className='body-content'>
      <Helmet>
        <title>Wishlist | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li><Link to={webUrl}>Home</Link></li>
              <li>Wishlist</li>
            </ol>
          </div>
        </Container>
      </div>
        {loading ? (
          <section>
            <Container>
              <Row className="gy-4">
                {[...Array(4)].map((_, index) => (
                  <Col lg={3} md={4} sm={6} key={index}>
                    <Skeleton animation="wave" variant="rounded" className="mb-1" width="100%" height={180} />
                    <Skeleton animation="wave" variant="text" className="mb-1" width="60%" height={20} />
                    <Skeleton animation="wave" variant="text" width="60%" height={20} />
                    <div className="d-flex justify-content-between flex-row">
                      <Skeleton animation="wave" variant="text" width="20%" height={50} />
                      <Skeleton animation="wave" variant="text" width="20%" height={50} />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        ) : (
          <>
            {isWishlistEmpty ? (
              <EmptyWishlist />
            ) : (
              <WishlistProducts />
            )}
          </>
        )}
    </div>
  );
};

export default Wishlist;
