import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link } from "react-router-dom";
import { useWishlist } from "../../Contexts/WishlistContext";
import { Tooltip } from "react-bootstrap";

export const WishlistButton = (props) => {
	const { handleWishlistClick } = useWishlist();

	const handleButtonClick = (event) => {
		event.preventDefault(); // Prevent the default behavior of the click event
		handleWishlistClick(props.product);
	  };

	return (
		<>
			<span onClick={handleButtonClick} className="wishlist-button">
				{props.heartToggle ? (
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Remove from your Wishlist</Tooltip>}
					>
						<i className="bi bi-heart-fill text-blue"></i>
					</OverlayTrigger>
				) : (
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Add to your Wishlist</Tooltip>}
					>
						<i className="bi bi-heart text-blue"></i>
					</OverlayTrigger>
				)}
			</span>
		</>
	);
};
