import React, { useEffect, useState } from "react";

const TapPayment = (props) => {
  return (
    <>
      {/* <form id="form-container" method="post"> */}
      {/* Tap element will be here */}
      <div id="element-container"></div>
      <div id="error-handler" role="alert"></div>
      {/* <div id="success" className="d-none position-relative float-right">
        Success! Your token is <span id="token"></span>
      </div> */}
      {/* Tap pay button */}
      {/* </form> */}
    </>
  );
};

export default TapPayment;
