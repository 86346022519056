import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useWishlist } from "../../Contexts/WishlistContext";
import { useCurr } from "../../Contexts/currContext";

export const WishlistProducts = () => {
  const {
    wishlistItems,
    setWishlistItems,
    getWishlistItems,
    handleRemoveItem,
  } = useWishlist();
const { selectedCurrency, currConvert } = useCurr();
  useEffect(() => {
    getWishlistItems();
  }, [getWishlistItems]);

  const handleRemoveWishlist = (itemId) => {
    setWishlistItems((current) =>
      current.filter((wishlistItems) => wishlistItems.product_id !== itemId)
    );

    handleRemoveItem(itemId);
    getWishlistItems();
  }; 

  return (
    <section className="list">
      <Container>
        <Row className="gy-4">
          {wishlistItems.map((item) => (
            <Col lg={3} md={4} sm={6} xs={6} key={item.wishlist_id}>
              <div className="wsk-cp-product">
                <Link
                  to={`/activity/${item.product_slug}`}
                  state={item.prodIdArr}
                >
                  <div className="wsk-cp-img">
                    <img
                      loading="lazy"
                      src={item.product_image}
                      alt={item.title}
                      className="img-fluid"
                      width="100%" 
                      height="auto"
                    />
                    <div className="add-cart">
                      <Link
                        onClick={() => handleRemoveWishlist(item.product_id)}
                      >
                        <i className="bi bi-heart-fill text-blue"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="wsk-cp-text">
                    <div className="title-product">
                      <h3>{item.product_name}</h3>
                    </div>
                    <div className="description-prod">
                      <p className="text-success">{item.content}</p>
                    </div>
                    <div className="description-prod">
                      <p className="text-success">{item.availstatus}</p>
                    </div>
                    <div className="card-footer">
                      <div className="wcf-left">
                        from
                        <span className="price">
                          <span className="currency">{selectedCurrency} </span>
                          {currConvert(item.original_price)}
                        </span>
                      </div>
                      <div className="wcf-right">
                        <p>
                          <i className="fa fa-star"></i> {item.rating}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default WishlistProducts;
