import React, { useEffect, useState } from "react";
import Highlights from "../Home/Highlights";
import { Container } from "react-bootstrap";
import Coupon from "../Home/Coupon";
import TourListBox from "./TourListBox";
import TrailerBox from "./TrailerBox";
import Activities from "./Activities";
import SubBanner from "./SubBanner";
import { useParams } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import { Helmet } from "react-helmet";
// import { useFilter } from "../../Contexts/filterContext";
function ThingstoDo() {
  const { city } = useParams();
  const mainApiUrl = BASE_URLS.main;
  const [cityId, setCityData] = useState([]);
  const [couponDetails, setCouponDetails] = useState(null);
  //Sconst { locationName } = useFilter();
  useEffect(() => {
    const getCities = async () => {
      const res = await fetch(
        `${mainApiUrl}/get_city_id.php?city_slug=` + city
      );
      const getdata = await res.json();
      setCityData(getdata.city_id);
    };
    getCities();
  }, [city, mainApiUrl]);

  useEffect(() => {
    fetchCouponDetails();
  }, []);

  const fetchCouponDetails = async () => {
    try {
      const response = await fetch(`${mainApiUrl}/get_coupon.php`);
      if (response.ok) {
        const data = await response.json();
        setCouponDetails(data);
      } else {
        throw new Error('Failed to fetch coupon details');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="body-content">
      <Helmet>Abu Dhabi Things To Do</Helmet>
      <SubBanner destId={cityId} />
      <Activities />
      <TrailerBox destId={cityId} />
      <Highlights />
      {couponDetails && couponDetails.coup_id > 0 && (
        <section>
          <Container>
            <Coupon />
          </Container>
        </section>
      )}
      <TourListBox destId={cityId} city={city} />
    </div>
  );
}
export default ThingstoDo;
