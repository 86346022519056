import React from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BASE_URLS from '../../baseUrl';
import failed from '../../Images/payment-failed.svg'

export const BookingFailure = () => {
    const webUrl = BASE_URLS.webUrl;
  return (
    <div className='body-content'>
    <Helmet>
        <title>Payment Failed | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Payment Failed</li>
            </ol>
          </div>
        </Container>
      </div>
    <section className='payments'>
        <Container>
            <Row>
                 <Col md={{ span: 8, offset: 2 }}>
                    <div className="booking fail">
                        <div className="booking-header">
                            <div className="bheader">
                                <img src={failed} width="250px" height="auto" className="img-fluid pt-3 pb-3" alt="Booking Failed" loading="lazy" />
                            </div>
                        </div>
                        <h3 className='font-weight-bold mt-3'>Payment Unsuccessful</h3>
                        <p>We apologize, but it appears that your payment could not be processed at this time.</p>
                    </div>
                    <div className='mt-4'>
                        <h6 className='font-weight-bold'> To ensure a smooth booking process, please review the following :</h6>
                        <ul>
                            <li>Double-check the payment information provided and try again</li>
                            <li>Ensure that your payment method has sufficient funds available</li>
                            <li>Check your internet connection and try the payment process again.</li>
                        </ul>
                        <p>If the issue persists or if you need any assistance, please contact our support team at <a href="mailto:info@rathin.com" target="_blank" rel="noreferrer">info@rathin.com</a> or call us at <a href="tel:+971 56 537 4934">+971 56 537 4934</a>. Our team will be happy to assist you in completing your booking.</p>
                        <p>We apologize for any inconvenience caused and thank you for your patience and understanding.</p>
                        <center className='mt-5'><Link to={webUrl}><Button variant='primary'>Back to Home</Button></Link></center>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    </div>
  )
}
