import React, { useState, useEffect } from "react";
import { EmptyReviews } from './EmptyReviews';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ReviewList } from './ReviewList';
import { RatingLists } from './RatingLists';
import { useCurr } from "../../Contexts/currContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import BASE_URLS from "../../baseUrl";

function Reviews() {
    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;
    const [custRat, setCustRat] = useState([]);
    const usr_id = localStorage.getItem("setAuthUser");
    const { selectedCurrency, currConvert } = useCurr();
    const [show, setShow] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null); // Track the selected order
    const [productsId, setProductsId] = useState(null);
    const [rating, setRating] = useState(0);
    const [validated, setValidated] = useState(false);
    const [ratingError, setRatingError] = useState(false);
    const [orderComplete, setOrderComplete] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getOrderList = async () => {
            try {
                const res = await fetch(
                    `${mainApiUrl}/get_order_complete.php?user_id=` + usr_id
                );
                const getdata = await res.json();

                if (getdata && getdata.order_list) {
                    //console.log('Order list from server:', getdata.order_list);
                    setOrderComplete(getdata.order_list);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
              }
        };

        getOrderList();
    }, [mainApiUrl, usr_id]);

    useEffect(() => {
        const getProduct = async () => {
            try {
                const res = await fetch(
                    `${mainApiUrl}/get_cust_review.php?user_id=`+usr_id
                );
                const getdata = await res.json();

                if (getdata && getdata.review_list) {
                    setCustRat(getdata.review_list);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
              }
        };

        getProduct();
    }, [mainApiUrl, usr_id]);

    const handleClose = () => {
        setShow(false);
        setSelectedOrder(null);
        setProductsId(null);
        setValidated(false);
        setRatingError(false);
    };

    const handleShow = (order) => {
        setSelectedOrder(order);
        setProductsId(order.products_id);
        setShow(true);
    };

    const handleRatingChange = (newRating) => {
        setRating(newRating);
        setRatingError(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false || rating === 0) {
            event.stopPropagation();

            if (rating === 0) {
                setRatingError(true);
            }
        } else {
            handleClose();

            try {
                const response = await fetch(`${mainApiUrl}/insert_customers_review.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        usr_id: localStorage.getItem("setAuthUser"),
                        order_id: selectedOrder.order_id,
                        products_id: productsId,
                        product_review_title: event.target.reviewTitle.value,
                        product_review: event.target.reviewDesc.value,
                        product_rating: rating,
                    }),
                });

                const result = await response.json();

                if (result.status) {
                    //console.log('Review submitted successfully:', result.result);
                    toast.success('Review submitted successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    const updatedOrderComplete = orderComplete.filter(order => order.order_id !== selectedOrder.order_id);
                    setOrderComplete(updatedOrderComplete);

                    const updatedCustRat = custRat.map(review => {
                        if (review.order_id === selectedOrder.order_id) {
                            return { ...review, product_rating: rating };
                        }
                        return review;
                    });
                    setCustRat(updatedCustRat);
                } else {
                    //console.error('Failed to submit review:', result.result, result.Error);
                    toast.error('Failed to submit review. Please try again.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                console.error('Error submitting review:', error);
            }
        }

        setValidated(true);
    };

    const updateOrderComplete = async (updatedOrderComplete, newRating) => {
      setOrderComplete(updatedOrderComplete);
    
      // Fetch the latest reviews after updating orderComplete
      try {
        const res = await fetch(`${mainApiUrl}/get_cust_review.php?user_id=${usr_id}`);
        const getdata = await res.json();
    
        if (getdata && getdata.review_list) {
          //console.log('Updated Review list from server:', getdata.review_list);
          setCustRat(getdata.review_list);
        }
      } catch (error) {
        console.error('Error fetching updated data:', error);
      }
    
      // If you want to trigger an update for RatingLists component,
      // you can update the custRat state as well
      // Note: You can remove the following block if you don't want to update custRat here
      setCustRat(prevCustRat => {
        return prevCustRat.map(review => {
          if (review.order_id === selectedOrder.order_id) {
            return { ...review, product_rating: newRating };
          }
          return review;
        });
      });
    };
    

    return (
        <>
            <h4 className='font-weight-bold'>Reviews</h4>
            <Tabs
                defaultActiveKey="pending"
                id="uncontrolled-tab-example"
                className="mb-3 mt-3"
            >
                <Tab eventKey="pending" title="Pending Reviews">
                {isLoading
                ? Array.from({ length: 2 }).map((_, index) => (
                    <Skeleton key={index} animation="wave" variant="rounded" width="100%" height={150} className="mb-2" />
                ))
                : orderComplete.length > 0 ? (
                    <ReviewList
                        orderComplete={orderComplete}
                        webUrl={webUrl}
                        handleShow={handleShow}
                        selectedCurrency={selectedCurrency}
                        currConvert={currConvert}
                        show={show}
                        handleClose={handleClose}
                        validated={validated}
                        handleSubmit={handleSubmit}
                        rating={rating}
                        handleRatingChange={handleRatingChange}
                        ratingError={ratingError}
                    />
                    ) : (
                    <EmptyReviews />
                    )}
                </Tab>
                <Tab eventKey="myReviews" title="My Reviews">
                {isLoading
                ? Array.from({ length: 2 }).map((_, index) => (
                    <Skeleton key={index} animation="wave" variant="rounded" width="100%" height={150} className="mb-2" />
                ))
                : custRat.length > 0 ? (
                        <RatingLists custRat={custRat} webUrl={webUrl} mainApiUrl={mainApiUrl} updateOrderComplete={updateOrderComplete} />
                    ) : (
                        <EmptyReviews />
                    )}
                </Tab>
            </Tabs>
        </>
    );
}

export default Reviews;
