import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import BASE_URLS from '../../baseUrl';

export const FaqForm = () => {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const [categories, setCategories] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${mainApiUrl}/get_faq_category.php`);
        const data = await response.json();
        setCategories(data.cat_list);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [mainApiUrl]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Create a data object with the form fields
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    // console.log(formData);
    // Perform your form submission logic here
    try {
        const response = await fetch(`${mainApiUrl}/add_faq.php?data=`,{
        method: 'POST',
        headers: {
          // Use 'multipart/form-data' for form data
          'Content-Type': 'multipart/form-data',
        },
        body: formData, // Use the FormData object directly
      });

      if (response.ok) {
        // Set a flag to display the success message
        setIsFormSubmitted(true);

        // Optionally, you can reset the form fields here
        e.target.reset();

        setTimeout(() => {
          setIsFormSubmitted(false);
        }, 5000); // Adjust the timeout duration as needed
      } else {
        console.error('Error submitting form data');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ask Questions | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Ask your questions</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="terms-conditions">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="section-header">
                <p className="text-left">
                  Ask your <span>questions</span>
                </p>
              </div>
              <Form method="post" onSubmit={handleFormSubmit}>
                {isFormSubmitted && (
                  // Display the success message
                  <Alert variant="success">
                    Your question has been submitted successfully!
                  </Alert>
                )}
                <Form.Group className="mb-3" controlId="category">
                  <Form.Label>Select Category *</Form.Label>
                  <Form.Select aria-label="--select--" name="category" required>
                    <option value="">--select--</option>
                    {categories.map((category) => (
                      <option key={category.cat_id} value={category.cat_id}>
                        {category.cat_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="mail">
                  <Form.Label>Your email address *</Form.Label>
                  <Form.Control name="mail" type="email" required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Your name *</Form.Label>
                  <Form.Control name="name" type="text" required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mobile">
                  <Form.Label>Mobile number *</Form.Label>
                  <Form.Control
                    name="mobile"
                    type="tel"
                    inputMode="numeric"
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    maxLength={12}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="more-details">
                  <Form.Label>Ask your question *</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="moreDetails"
                    rows={5}
                    required
                  />
                </Form.Group>
                <Form.Group className="pt-3 mb-3">
                  <Button type="submit" name='ask_question' variant="primary">
                    Ask Question
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
