import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from 'google-login-react';
import { useAuth } from '../Contexts/AuthContext';
import BASE_URLS from '../baseUrl';

function LoginModal({
  onHide,
  showLogin,
  openSignupModal,
  handleGoogleLoginFailure,
  handleSuccessfulLogin,
  handleGoogleLoginSuccess,
}) {
  const { setAuthUser } = useAuth();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const payload = {
      username: email,
      password: password,
    };

    try {
      const response = await fetch(
        `${mainApiUrl}/check_login.php`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.result === 'Success') {
          // Login successful
          const userData = data.user_data;
          handleSuccessfulLogin(userData);
          onHide();
          toast.success('Login successful!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setAuthUser(userData);
          //localStorage.setItem('setAuthUser', JSON.stringify(userData));
          localStorage.setItem('setAuthUser', userData.usr_id);

        } else {
          setErrorMessage('Invalid email or password.');
        }
      } else {
        setErrorMessage('An error occurred while logging in.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while logging in.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage('');
  };

  const handleModalClose = () => {
    setErrorMessage(''); // Clear the error message when modal is closed
    onHide();
  };

  return (
    <>
      <Modal
        show={showLogin}
        onHide={handleModalClose}
        size="md"
        centered
        onExited={() => {
          // Clear the input fields and error message when the modal is closed
          setEmail('');
          setPassword('');
          setErrorMessage('');
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
        <Modal.Body className='text-center p-5 pt-0'>
          <h4 className='font-weight-bold'>Login</h4>
          <p>to continue exploring</p>
          <GoogleLogin
            clientId="69923402974-r6v71a76q6m149th2ut2v02ms67cpeh3.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={handleGoogleLoginSuccess}
            onFailure={handleGoogleLoginFailure}
            cookiePolicy={'single_host_origin'}
            render={(renderProps) => (
              <span className='signup-buttons'>
                <Button
                  variant="outline-dark"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    src="https://developers.google.com/identity/images/g-logo.png"
                    alt="Google"
                    width="18"
                    height="auto"
                    className='mr-1'
                    loading="lazy"
                  />
                  Continue with Google
                </Button>
              </span>
            )}
          /> 
          <p className="text-divider text-center">
            <span>or</span>
          </p>
          <h6 className='font-weight-bold pt-4'>Continue with email</h6>
          <form onSubmit={handleLogin} className="Login-form">
            <div className="form-group mt-3">
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                required
                value={email}
                onChange={handleEmailChange}
                autoComplete="username"
              />
            </div>
            <div className="form-group mt-3 position-relative d-flex align-items-center">
              <input
                type={passwordVisible ? 'text' : 'password'}
                className="form-control"
                name="password"
                placeholder="Password"
                required
                value={password}
                onChange={handlePasswordChange}
                autoComplete="current-password"
              />
              <span
                className="svg-icon svg-icon-2 position-absolute mx-4 right-0"
                onClick={togglePasswordVisibility}
              >
                <i
                  className={`text-blue bi bi-${
                    passwordVisible ? 'eye-slash-fill' : 'eye-fill'
                  }`}
                ></i>
              </span>
            </div>
            <p className='pt-2 font-weight-bold'>
              <Link to={`${webUrl}forgot-password`} onClick={onHide}>
                Forgot Password?
              </Link>
            </p>
            <div className="form-group mt-3 text-center">
              <Button type='submit' variant='primary'>
                Log in
              </Button>
            </div>
            {errorMessage && (
              <p className="text-danger pt-3">{errorMessage}</p>
            )}
          </form>
          <h6 className='font-weight-bold pt-4'>
            Don’t have an account?{' '}
            <Link onClick={() => { onHide(); openSignupModal(); }}>
              Sign up
            </Link>
          </h6>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;
