import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from '../../baseUrl';
import { Skeleton } from '@mui/material';

function BrowseCategory() {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const [aboutHigh, setAboutHigh] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAboutHigh = async () => {
      try {
        const response = await fetch(`${mainApiUrl}/get_faq_category.php`);
        const data = await response.json();
        if(data){
          setAboutHigh(data.cat_list.slice(0, 6)); // Limit to 6 categories  
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    getAboutHigh();
  }, [mainApiUrl]);

  // Hide the entire section if prodData length is zero
  if (aboutHigh && aboutHigh.length === 0 && !loading) {
    return null;
  }

  return (
    <section className='section-bg'>
      <Container>
        <div className="section-header">
          <p>Browse by <span>categories</span></p>
        </div>
        {loading ? (
          <Row className="gy-3">
            {Array.from({ length: 6 }).map((_, index) => (
              <Col lg={4} md={6} sm={12} key={index}>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={130} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="gy-3">
            {aboutHigh.map((high) => (
              <Col lg={4} md={6} sm={12} key={high.cat_id}>
                <Link to={`${webUrl}articles/${high.cat_slug}`}>
                  <div className="icon-box d-grid">
                    <i className={high.cat_icon}></i>
                    <span>
                      <h4>{high.cat_name}</h4>
                      <p>{high.cat_desc}</p>
                    </span>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  )
}

export default BrowseCategory;
