import React from 'react'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import booking from '../../Images/bookings.svg';
import { Button } from 'react-bootstrap';
import BASE_URLS from '../../baseUrl';

export const EmptyBookings = () => {
  const webUrl = BASE_URLS.webUrl;
  return (
    <Row className='text-center'>
        <Col md={{ span: 6, offset: 3 }}>
            <img src={booking} width="250px" height="auto" className='img-fluid pt-3 pb-3' alt="forgot password" loading="lazy" />
            <p>Where is your next adventure? Lets help you find a new on</p>
            <Link to={webUrl}><Button variant="primary">Explore more</Button></Link>
        </Col>
    </Row>

  )
}
