
// Define multiple base URLs as an object
const BASE_URLS = {

  // Server link
  main: 'https://rathin.com/webadmin/api/',
  webUrl: '/',

  // main: 'http://localhost/websites/rahintours/api',
  // webUrl: '/rathintours-react/',

  payUrl: 'https://rathin.com/',
  pk_live: 'pk_live_taTrCghvKeHz576b9IfcEopd',
  tapApiUrl: "https://api.tap.company/v2",

};
export default BASE_URLS;
