import { useState, useEffect } from "react";
import React from "react";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BASE_URLS from "../../baseUrl";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "bootstrap-icons/font/bootstrap-icons.css";

import { Link } from "react-router-dom";
import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import { useCurr } from "../../Contexts/currContext";

const ProductViewOne = () => {
  const { wishlistItems, wishlistStatus, getWishlistItems, setWishlistStatus } = useWishlist();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const { selectedCurrency, currConvert } = useCurr();
  const [isLoading, setIsLoading] = useState(true);
  const [posiNo, setPosiNo] = useState(5);

  const [prodData, setProducts] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [dispProdData, setDispProdData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        posiNo: posiNo,
      };
      try {
        const response = await fetch(
          `${mainApiUrl}/get_products_display.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );
        const getdata = await response.json();
        if (getdata && getdata.status == 1) {
          setProducts(getdata.prod_disp.prod_list);
          setMainData(getdata);
          setDispProdData(getdata.prod_disp);
        } else {
          setProducts([]);
          setMainData([]);
          setDispProdData([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (posiNo) {
      fetchData();
    }
  }, [mainApiUrl]);

  useEffect(() => {
    getWishlistItems();
    if(mainData && mainData.prod_disp){
      const allProducts = mainData.prod_disp.flatMap(item => item.prod_list);
      setProducts(allProducts);
    }

  }, []);

  useEffect(() => {
    // Initialize the wishlist status object
    if (wishlistItems && prodData !== "") {
      const initialWishlistStatus = {};

      prodData?.forEach((product) => {
        initialWishlistStatus[product.product_id] = wishlistItems.some(
          (item) => item.product_id === product.product_id
        );
      });
      setWishlistStatus(initialWishlistStatus);
    }
  }, [wishlistItems, prodData]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  // Hide the entire section if prodData length is zero
  if (dispProdData && dispProdData.length === 0 && !isLoading) {
    return null;
  }

  return (
    <section className="recently-viewed">
       {dispProdData.map((itemMain, index) => (
      <Container key={index}>
        <div className="section-header">
          <p>
            {itemMain.dis_title}
          </p>
        </div>
        <Row className="gy-4">
          <Slider {...settings}>
            {isLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={335} />
                </Col>
              ))
              : itemMain.prod_list.map((item, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <div className="wsk-cp-product">
                    <Link
                      to={`${webUrl}activity/${item.product_slug}`}
                      state={item.prodIdArr}
                    >
                      <div className="wsk-cp-img">
                        <img
                          src={item.product_image}
                          alt={item.product_name}
                          className="img-fluid"
                          loading="lazy"
                          width="100%" 
                          height="auto"
                        />
                        <div className="add-cart">
                          <WishlistButton
                            heartToggle={wishlistStatus[item.product_id]}
                            product={item.product_id}
                          />
                        </div>
                      </div>
                      <div className="wsk-cp-text">
                        <div className="title-product">
                          <h3>{item.product_name}</h3>
                        </div>
                        <div className="description-prod">
                        {(item.availablity) == "Not Available" ? (
                          <p className="text-danger">{item.availablity}</p>
                        ) : <p className="text-success">{item.availablity}</p>}
                        </div>
                        <div className="card-footer">
                          <div className="wcf-left">
                            from
                            {item.product_price_starts <
                              item.product_price_starts_old ? (
                              <span className=" wcf-left">
                                <s>
                                  <span className="currency">
                                    {selectedCurrency}
                                  </span>{" "}
                                  {currConvert(
                                    item.product_price_starts_old
                                  )}
                                </s>
                              </span>
                            ) : null}
                            <span className="price">
                              <span className="currency">
                                {selectedCurrency}
                              </span>{" "}
                              {currConvert(item.product_price_starts)}
                            </span>
                          </div>
                          <div className="wcf-right">
                            <p>
                              <i className="bi bi-star-fill"></i>{" "}
                              {item.product_rating}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))}
          </Slider>
        </Row>
      </Container>
            ))}
    </section>
  );
};

export default ProductViewOne;
