import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BASE_URLS from '../../baseUrl';
import "bootstrap-icons/font/bootstrap-icons.css";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from MUI

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerHighlights = () => {

  const [infoItems, setInfoItems] = useState([]);
  const mainApiUrl = BASE_URLS.main;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${mainApiUrl}/get_banner_highlights.php`);
        const data = await response.json();

        if (data.status === "1") {
          setInfoItems(data.high_list);
        } else {
          console.error("Failed to fetch data:", data.result);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  let slidesToShow = 5; // Default value for 4 or more items

  if (infoItems.length === 4) {
    slidesToShow = 3;
  } 
  else if (infoItems.length === 3) {
    slidesToShow = 3;
  } else if (infoItems.length < 3) {
    slidesToShow = infoItems.length;
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: slidesToShow > 1 ? slidesToShow - 1 : 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
    {isLoading ? (
      <Row className="contact gy-4">
        {Array.from({ length: 4 }).map((_, index) => (
         <Col key={index} lg={3} md={6} sm={12}>
            <div className="d-flex gap-1">
              <Skeleton animation="wave" variant="circular" width={50} height={50} />
              <Skeleton animation="wave" variant="text" width="100%" height="auto"/>
            </div>
          </Col>
        ))}
      </Row>
    ) : (
      <>
      {infoItems.length > 0 && (
        <Row className="contact gy-4">
          <Slider {...settings}>
            {infoItems.map((item) => (
              <Col key={item.banner_content_id}>
                <div className="info-item d-flex align-items-center">
                  <i className={`icon ${item.cont_icon} flex-shrink-0`}></i>
                  <div>
                    <h3>{item.cont_title}</h3>
                    <p>{item.cont_desc}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Slider>
        </Row>
      )}
      </>
    )}
    </>
  );
};

export default BannerHighlights;
