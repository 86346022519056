import React from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

export const PastBookingList = ({ webUrl, selectedCurrency, currConvert, passedOrder }) => {
  
    const formatReviewDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
        };

        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    };



  return (
    <Row className="list-view">
        <Col md={12}>
            {passedOrder.map((pastList, index) => (
            <div className="wsk-cp-product mb-2" key={index}>
                <Link to={webUrl}>
                    <div className="wsk-cp-img">
                        <img loading="lazy" src={pastList.image} alt={pastList.prod_name} className="img-fluid" width="100%" height="auto"/>
                    </div>
                    <div className="wsk-cp-text">
                        <div>
                            <div className="title-product">
                                <h3>{pastList.prod_name}</h3>
                            </div>
                            <div className="description-prod">
                            <p className={pastList.status == 'Cancelled' || pastList.status == 'Cancel Requested' ? 'text-danger' : 'text-success'}>
                                {pastList.status} {formatReviewDate(pastList.booked_date)}
                            </p>

                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="wcf-left">
                                Total paid
                                <span className="price">
                                    <span className="currency">{selectedCurrency}</span>{" "}
                                    {currConvert(pastList.amount)}
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            ))}
        </Col>
    </Row>
  );
};
