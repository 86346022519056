import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useAuth } from '../../Contexts/AuthContext';
import BASE_URLS from "../../baseUrl";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";

function Profile() {
  const { authUser, isLoggedInWithGmail } = useAuth();
  const mainApiUrl = BASE_URLS.main;
  const [showPasswordSetup, setShowPasswordSetup] = useState(isLoggedInWithGmail);
   const [updatedUserData, setUpdatedUserData] = useState({
    usr_fname: '', // Initialize with empty values
    usr_lname: '',
    usr_mobile: '',
    usr_address: '',
  });

  useEffect(() => {
    if (authUser) {
      // Set initial data once authUser is available
      setUpdatedUserData({
        usr_fname: authUser.usr_fname || '',
        usr_lname: authUser.usr_lname || '',
        usr_mobile: authUser.usr_mobile || '',
        usr_address: authUser.usr_address || '',
      });
    }
  }, [authUser]);

  const userId = parseInt(localStorage.getItem("setAuthUser"));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserData({ ...updatedUserData, [name]: value });
  };

  const handleInputChange_mob = (value, country, event, formattedValue) => {
    // The `value` parameter contains the updated phone number value
    // Update the state with the new value
    setUpdatedUserData({ ...updatedUserData, usr_mobile: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    // Send the updated data to the server
    const response = await fetch(`${mainApiUrl}update_user_data.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        updatedUserData: updatedUserData,
      }),
    });
    //console.log(userId);
    if (response.ok) {
      // The data was successfully updated on the server
      toast.success('Your profile was successfully updated!', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error('Please check your information. Your profile update failed!', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    setShowPasswordSetup(isLoggedInWithGmail); // Update showPasswordSetup based on user's login method
  }, [isLoggedInWithGmail]);

  return (
    <>
      {showPasswordSetup ? ( 
        <>
          <h4 className='font-weight-bold'>Account Information</h4>
          <p className='small'>You logged in with your Gmail account</p>
        </>
      ) : (
        <>
      <h4 className='font-weight-bold'>Account Information</h4>
      <p className='small'>We’ll notify you of booking changes using your provided information.</p>
      <Form className='mt-5' onSubmit={handleFormSubmit}>
        <Row className="mb-4 gy-4">
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="firstName">
              <Form.Label>First name * </Form.Label>
              <Form.Control
                name="usr_fname"
                type="text"
                value={updatedUserData.usr_fname}
                onChange={handleInputChange}
                required
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="lastName">
              <Form.Label>Last name * </Form.Label>
              <Form.Control
                name="usr_lname"
                type="text"
                value={updatedUserData.usr_lname}
                onChange={handleInputChange}
                required
                maxLength={50}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4 gy-4">
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="contactNumber">
              <Form.Label>Contact number *</Form.Label>
              <PhoneInput
                  name="usr_mobile"
                  country={"ae"}
                  value={updatedUserData.usr_mobile}
                  onChange={handleInputChange_mob}
                  placeholder="Mobile Number *"
                  required
                  maxLength={15}
                  // onChange={(value) => handleInput({ target: { name: "mobile_number", value } }) }
              />
              {/* <Form.Control
                name="usr_mobile"
                type="tel"
                value={updatedUserData.usr_mobile}
                onChange={handleInputChange}
                required
                maxLength={15}
              />*/}
            </Form.Group> 
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                name="usr_logname"
                type="email"
                value={authUser?.usr_logname}
                readOnly
                maxLength={250}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4 gy-4">
          <Col lg={12} md={12} sm={12}>
            <Form.Group controlId="countryResidence">
              <Form.Label>Country of residence *</Form.Label>
              <Form.Control
                name="usr_address"
                type="text"
                value={updatedUserData.usr_address}
                onChange={handleInputChange}
                required
                maxLength={500}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      </>
    )}
    </>
  );
}

export default Profile;
