import React from 'react'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emptyAct from '../../Images/empty-activity.svg';
import { Button } from 'react-bootstrap';
import BASE_URLS from '../../baseUrl';

export const EmptyActivity = () => {
    const webUrl = BASE_URLS.webUrl;
  return (
    <Row className='text-center pt-5'>
        <Col md={{ span: 6, offset: 3 }}>
            <img src={emptyAct} className='img-fluid pt-3 pb-3' alt="Empty Activity" width="100%" height="auto" loading="lazy" />
            <p>We're sorry, but currently, there are no tours available in the selected category. Our offerings are constantly evolving, so please check back later or explore other categories for exciting tour options.</p>
            <Link to={webUrl}><Button variant="primary">Explore more</Button></Link>
        </Col>
    </Row>
  )
}
