import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import BASE_URLS from '../../baseUrl';

export const ContactForm = () => {
  const mainApiUrl = BASE_URLS.main;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormData();
  };

  const sendFormData = () => {
    // Create a JavaScript object representing the form data
    const requestData = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    // Send a POST request to the PHP API endpoint
    fetch(`${mainApiUrl}/add_contact_request.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse JSON response
      })
      .then((data) => {
        if (data.status === 'success') {
          // Handle success (e.g., show a success message)
          toast.success(data.message);
          // Clear the form
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
        } else {
          // Handle error (e.g., show an error message)
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle error (e.g., show an error message)
        toast.error('An error occurred while sending the message.');
      });
  }; 

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <input
          type="text"
          name="subject"
          className="form-control"
          placeholder="Subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group mt-3 pb-3">
        <textarea
          name="message"
          className="form-control"
          rows="7"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="form-group mt-3 text-center">
        <Button type="submit" name='send_message' variant="primary">
          Send Message
        </Button>
      </div>
    </form>
  );
};
