import React from 'react'
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import rating from '../../Images/customer_ratings.svg';
import { Button } from 'react-bootstrap';
import BASE_URLS from '../../baseUrl';

export const EmptyReviews = () => {
  const webUrl = BASE_URLS.webUrl;
  return (
    <Row className='text-center'>
        <Col md={{ span: 6, offset: 3 }}>
            <h4 className='font-weight-bold text-center'>No reviews yet</h4>
            <img src={rating} width="300px" height="auto" className='img-fluid pt-3 pb-3' alt="reviews" loading="lazy"/>
            <p className='pb-3'>Get started by booking some activities on our platform. Once you’ve completed the activity, you can leave your feedback here.</p>
            <Link to={webUrl}><Button variant="primary">Explore more</Button></Link>
        </Col>
    </Row>

  )
}
