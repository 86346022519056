import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../Contexts/AuthContext';
import BASE_URLS from '../../baseUrl';
import Reviews from './Reviews';
    
function ReviewTab({ navigate }) {
  const webUrl = BASE_URLS.webUrl;
  const handleGoogleLogout = () => {
    setIsLoggedIn(false);
    setIsLoggedInWithGmail(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('lastAccessTime');
    localStorage.removeItem('setIsLoggedInWithGmail');
    navigate(webUrl);
    toast.success('Logout successful!', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const {
    isLoggedIn,
    setIsLoggedIn,
    setIsLoggedInWithGmail
  } = useAuth();

  useEffect(() => {
    // Optional: Fetch booking data based on isLoggedIn status
    // Example: if (isLoggedIn) fetchBookings();
  }, [isLoggedIn]);
  
    return (
    <div className='body-content'>
      <Helmet>
        <title>Reviews | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Reviews</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className='faq overflow-visible'>
        <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey='reviews'>
                <Row className='gx-5 gy-3'>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column sticky-top">
                        <Nav.Item>
                            <Nav.Link as={Link} to={`${webUrl}wishlist`}><i className='bi bi-heart'></i> Wishlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`${webUrl}account/bookings`}><i className='bi bi-calendar-check'></i> Bookings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="reviews"><i className='bi bi-star'></i> Reviews</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`${webUrl}account/profile`}><i className='bi bi-person'></i> Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`${webUrl}account/settings`}><i className='bi bi-gear'></i> Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={handleGoogleLogout}><i className='bi bi-box-arrow-right'></i> Logout </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="reviews">
                            <Reviews />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
                </Row>
            </Tab.Container>
        </Container>
      </section>
    </div>
    );
}

export default ReviewTab;