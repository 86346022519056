import React from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Rating from 'react-rating-stars-component';

export const ReviewList = ({ webUrl, orderComplete, handleShow, selectedCurrency, currConvert, show, handleClose, validated, handleSubmit, rating, handleRatingChange, ratingError }) => {

    const formatReviewDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
        };

        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <Row className="list-view">
            <Col md={12}>
                {orderComplete.map((orderList) => (
                    <div className="wsk-cp-product mb-3" key={orderList.order_id}>
                        <Link to={webUrl}>
                            <div className="wsk-cp-img">
                                <img loading="lazy" src={orderList.image} alt={orderList.prod_name} className="img-fluid" width="100%" height="auto"/>
                            </div>
                            <div className="wsk-cp-text">
                                <div>
                                    <div className="title-product">
                                        <h3>{orderList.prod_name}</h3>
                                    </div>
                                    <div className="description-prod">
                                        <p className="text-success">Completed on  {formatReviewDate(orderList.date)}</p>
                                        <Link
                                            className="mt-3 text-blue"
                                            onClick={() => handleShow(orderList)}
                                        >
                                            <i className="bi bi-star"></i> Rate & Review
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="wcf-left">
                                        Total paid
                                        <span className="price">
                                            <span className="currency">{selectedCurrency}</span>{" "}
                                            {currConvert(orderList.amount)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={handleClose}
                >
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Rate this product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Your ratings *</Form.Label>
                                <Rating
                                    count={5}
                                    size={30}
                                    value={rating}
                                    onChange={handleRatingChange}
                                    activeColor="#ffd700"
                                />
                                {ratingError && (
                                    <div className="invalid-feedback d-block">
                                        Please provide a rating.
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Title *</Form.Label>
                                <Form.Control name="reviewTitle" type="text" maxLength={250} required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description *</Form.Label>
                                <Form.Control as="textarea" name="reviewDesc" maxLength={1000} rows={5} required />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" name="review_save" variant="primary">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Row>
    );
};
