import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from '../../baseUrl';
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

function BookNow() {
    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;
    const [feaDataArr, setFeaDataArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const getProduct = async () => {
            try {
                const res = await fetch(`${mainApiUrl}/get_subbanner_dets.php`);
                if (!res.ok) {
                    throw new Error('Failed to fetch data');
                }
                const getdata = await res.json();
                setFeaDataArr(getdata.feature_list);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // You can handle errors here, e.g., show an error message or set state to indicate an error
            } finally {
                setIsLoading(false);
              }
        };
    
        getProduct();
    }, [mainApiUrl]);

    const columnSize = feaDataArr.length === 1 ? 12 : 6;

    if (feaDataArr.length === 0 && !isLoading) {
        return null;
      }
    
    return (
        <section className='booknow'>
            <Container>
                <Row className='gy-3'>
                    {isLoading
                        ? Array.from({ length: 2 }).map((_, index) => (
                            <Col lg={6} md={6} sm={12} key={index}>
                                <Skeleton animation="wave" variant="rounded" width="100%" height={180} />
                            </Col>
                            ))
                        : feaDataArr.map((item, index) => (
                        <Col lg={columnSize} md={columnSize} xs={12} key={index}>
                            <div className="image-banner">
                                <img src={item.img_file} alt={item.img_name} loading="lazy" width="100%" 
                                height="auto"/>
                                <div className="overlay"></div>
                                <div className="button">
                                    <Link to={`${webUrl}activity/${item.product_slug}`}>
                                        Book Now
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>    
            </Container>
        </section>
    );
}

export default BookNow;
