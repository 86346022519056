import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import TourList from "./TourList";
import { useNavigate } from "react-router-dom";
import BASE_URLS from "../../baseUrl";

function ToursTab() {
  const navigate = useNavigate();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const [activeTab, setActiveTab] = useState("");

  const handleTabSelect = (title) => {
    setActiveTab(title);
  };

  const [cityData, setCityData] = useState([]);
  useEffect(() => {
    const getActivities = async () => {
      const res = await fetch(`${mainApiUrl}/get_cities.php`);
      const getdata = await res.json();
      setCityData(getdata.city_data_cont);
      setActiveTab(getdata.city_data_cont[0].destTitle);
    };
    getActivities();
  }, [mainApiUrl]);

  const handleButtonClick = () => {
    navigate(`${webUrl}activity-list`, { state: { activeTab: activeTab } });
  };

  return (
    <section className="section-bg tour-tabs list-view">
      <Container>
        <div className="section-header">
          <p>
            Popular things to do in <span>{activeTab}</span>
          </p>
        </div>
        <Row className="tour-tabs">
          <Col>
            <Tabs
              id="tour-tabs"
              activeKey={activeTab}
              onSelect={(title) => handleTabSelect(title)}
            >
              {cityData.map((item, index) => (
                <Tab
                  key={index}
                  eventKey={item.destTitle}
                  title={
                    <span>
                      <i className="bi bi-geo-alt"></i> {item.destTitle}
                    </span>
                  }
                >
                    <Row className="gy-3 pt-5">
                      <TourList activeTab={activeTab} cityData={item} />
                    </Row>
                </Tab>
              ))}
            </Tabs>
            <Row className="tablist">
              <Col className="d-flex justify-content-center mt-5">
                <Button variant="primary" onClick={handleButtonClick}>
                  View All Activities
                </Button>
                {}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ToursTab;
