import React from 'react';

const DownloadTick = ({ fileUrls }) => {
  const handleDownloadAll = async () => {
    try {
      for (const fileUrl of fileUrls) {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Failed to download file (HTTP status ${response.status})`);
        }
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.error('Error downloading files:', error.message);
    }
  };

  return (
    <div>
      {fileUrls && fileUrls.length > 0 ? (
        <button onClick={handleDownloadAll} className="btn btn-primary">Download All E-tickets</button>
      ) : (
        <button disabled className="btn btn-primary">No E-tickets to Download</button>
      )}
    </div>
  );
};

export default DownloadTick;
