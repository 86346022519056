import React, { useEffect, useState } from "react";
import { useCurr } from "../../Contexts/currContext";

const TimeConverter = (inputTime) => {
  if (inputTime == undefined) {
    return '';
  }
  const date = new Date(`2000-01-01T${inputTime}`);
  const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
  return formattedTime;
};


export const CheckoutSidebar = (props) => {
  const { selectedCurrency, currConvert } = useCurr();
  const [totalAmount, setTotalAmount] = useState(
    props.bookingDetails?.totalAmount
  );
  const [totalAddonAmount, setTotalAddonAmount] = useState(
    props.bookingDetails?.totalAmount
  );

  useEffect(() => {
  }, [props.bookingDetails_addon]);
  const addonTotalAmount = props.bookingDetails_addon.length === 0 ? 0 : props.bookingDetails_addon.totalAmount;
  const addonTotalAmountDisp = props.bookingDetails_addon.length === 0 ? 0 : props.bookingDetails_addon.totalAmountDisp;

  const bookingDetailsData = props.bookingDetails;
  const bookingDetails_addonData = props.bookingDetails_addon;
  const totalAmountDisp = props.bookingDetails?.totalAmountDisp + addonTotalAmountDisp;
  const totalAmountAddonDisp = props.bookingDetails_addon?.totalAmountDisp;

  useEffect(() => {
    if (props.discountPercent > 0 && bookingDetailsData) {
      const totalAmount = props.totalAfterDiscount(
        (props.bookingDetails.totalAmount + addonTotalAmount),
        props.discountPercent
      );
      setTotalAmount(totalAmount);
    } else {
      setTotalAmount((props.bookingDetails?.totalAmount + addonTotalAmount));
    }
  }, [props.discountPercent]);

  useEffect(() => {
    if (props.discountPercent > 0 && bookingDetails_addonData) {
      const totalAddonAmount = props.totalAfterDiscount(
        props.bookingDetails.totalAmount,
        props.discountPercent
      );
      setTotalAddonAmount(totalAddonAmount);
    } else {
      setTotalAddonAmount(props.bookingDetails?.totalAmount);
    }
  }, [props.discountPercent]);

  const [prodTags, setProdTags] = useState([]);
  useEffect(() => {
    if (props) {
      setProdTags(props.bookingDetails.pro_arr);
    }
  }, [props]);

  const [prodAddonTags, setProdAddonTags] = useState([]);
  useEffect(() => {
    if (props.bookingDetails_addonData && props.bookingDetails_addonData.pro_arr) {
      setProdAddonTags(props.bookingDetails_addonData.pro_arr);
    }
  }, [props.bookingDetails_addonData]);


  // console.log(props.bookingDetails.pro_arr);
  // console.log("checkdata side...", bookingDetailsData?.totalAmountDisp);

  return (
    <>
      <div className="checkout-sidebar sticky-top">
        <h5>{bookingDetailsData?.product_id}</h5>
        <p>{bookingDetailsData?.allTicketData}</p>

        {prodTags &&
          Array.isArray(prodTags) &&
          prodTags.map((tags, index) => (
            <span className="badge bg-secondary pt-2 pb-2" key={index}>
              {tags.pro_tag_name}
            </span>
          ))}

        <div className="checkout-table table-responsive">
          <table>
            <thead>
              <tr>
                <th className="checkout-th-date-txt">Date</th>
                <th className="checkout-th-date">
                  {bookingDetailsData?.selectedDate}{' '}
                  {TimeConverter(bookingDetailsData?.booking_time)}
                </th>
              </tr>
            </thead>
            <tbody>
              {bookingDetailsData?.selectedTickets.map(
                (ticket_data, index) =>
                  ticket_data.quantity > 0 && (
                    <tr key={index}>
                      <td className="checkout-tb-age">
                        {ticket_data.tick_ids_name}
                      </td>
                      <td className="checkout-tb-age-txt">
                        {ticket_data.quantity} x {selectedCurrency}{" "}
                        {currConvert(ticket_data.inv_dets[0].sell_price)}
                        { }
                      </td>
                    </tr>
                  )
              )}
              {bookingDetails_addonData != '' &&
                bookingDetails_addonData.selectedTickets != '' ? (
                null
              ) : (
                <>
                  
                </>
              )}

            </tbody>
            {bookingDetails_addonData != '' &&
              bookingDetails_addonData.selectedTickets != '' ? (
              null
            ) : (
              <tfoot>
                {props.discountPercent !== undefined &&
                    props.discountPercent > 0 && (
                      <tr>
                        <td className="checkout-tf-pricetxt green_discount">
                          {" "}
                          Discount {(props.discountPercent).toFixed(2)} %
                        </td>
                        <td className="checkout-tf-price green_discount">
                          - {selectedCurrency} {""}
                          {(
                            (currConvert(props.bookingDetails.totalAmount + addonTotalAmount) *
                              props.discountPercent) /
                            100
                          ).toFixed(2)}
                        </td>
                      </tr>
                    )}{" "}

                <tr>
                  <td className="checkout-tf-pricetxt">
                    Total<span> Inclusive of taxes</span>
                  </td>
                  <td className="checkout-tf-price">
                    {selectedCurrency} {" "}
                    {props.discountPercent !== undefined && props.discountPercent > 0 ? (currConvert(totalAmountDisp) - (((currConvert(totalAmountDisp)) * props.discountPercent) / 100)).toFixed(2) : currConvert(totalAmountDisp)}
                    {" "}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>

          {bookingDetails_addonData != '' &&
            bookingDetails_addonData.selectedTickets != '' && (
              <>
                {/* <hr></hr> */}
                <h5 className="pt-4">{bookingDetails_addonData?.product_id}</h5>
                <p>{bookingDetails_addonData?.allTicketData}</p>

                {prodAddonTags &&
                  Array.isArray(prodAddonTags) &&
                  prodAddonTags.map((tags, index) => (
                    <span className="badge bg-secondary pt-2 pb-2" key={index}>
                      {tags.pro_tag_name}
                    </span>
                  ))}

                <table>
                  <thead>
                    <tr>
                      <th className="checkout-th-date-txt">Date</th>
                      <th className="checkout-th-date">
                        {bookingDetails_addonData?.selectedDate}{' '}
                        {TimeConverter(bookingDetails_addonData?.booking_time)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingDetails_addonData?.selectedTickets.map(
                      (ticket_data, index) =>
                        ticket_data.quantity > 0 && (
                          <tr key={index}>
                            <td className="checkout-tb-age">
                              {ticket_data.tick_ids_name}
                            </td>
                            <td className="checkout-tb-age-txt">
                              {ticket_data.quantity} x {selectedCurrency}{" "}
                              {currConvert(ticket_data.inv_dets[0].sell_price)}
                              { }
                            </td>
                          </tr>
                        )
                    )}

                  </tbody>
                  {/* <tfoot>
                    <tr>
                      <td className="checkout-tf-pricetxt">
                        Total<span> Inclusive of taxes</span>
                      </td>
                      <td className="checkout-tf-price">
                        {selectedCurrency} {" "}
                        {props.discountPercent !== undefined && props.discountPercent > 0 ? (currConvert(totalAmountAddonDisp) - (((currConvert(totalAmountAddonDisp)) * props.discountPercent) / 100)).toFixed(2) : currConvert(totalAmountAddonDisp)}
                        {" "}
                      </td>
                    </tr>
                  </tfoot> */}
                  <tfoot>
                    {props.discountPercent !== undefined &&
                      props.discountPercent > 0 && (
                        <tr>
                          <td className="checkout-tf-pricetxt green_discount">
                            {" "}
                            Discount {(props.discountPercent).toFixed(2)} %
                          </td>
                          <td className="checkout-tf-price green_discount">
                            - {selectedCurrency} {""}
                            {(
                              (currConvert(props.bookingDetails.totalAmount + addonTotalAmount) *
                                props.discountPercent) /
                              100
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )}{" "}
                    <tr>
                      <td className="checkout-tf-pricetxt">
                        Total<span> Inclusive of taxes</span>
                      </td>
                      <td className="checkout-tf-price">
                        {selectedCurrency} {" "}
                        {props.discountPercent !== undefined && props.discountPercent > 0 ? (currConvert(totalAmountDisp) - (((currConvert(totalAmountDisp)) * props.discountPercent) / 100)).toFixed(2) : currConvert(totalAmountDisp)}
                        {" "}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </>
            )}{" "}

        </div>
      </div>
      {selectedCurrency != 'AED' && (
        <div className="checkout-sidebar sticky-top mt-5">
          <h5><i className="bi bi-patch-exclamation-fill"></i> You will be charged in AED</h5>
          <p>The price shown here is in ({selectedCurrency}) as per the current conversion rate. You will be charged in AED (AED&nbsp;
            {props.discountPercent !== undefined && props.discountPercent > 0 ? ((totalAmountDisp) - ((((totalAmountDisp)) * props.discountPercent) / 100)).toFixed(2) : (totalAmountDisp.toFixed(2))}
            )
          </p>
        </div>
      )}

    </>
  );
};
