import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import SearchResult from "./Home/searchResult";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../Contexts/AuthContext";
import { useCurr } from "../Contexts/currContext";
import SigninModal from "./SigninModal";
import BASE_URLS from "../baseUrl";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from MUI

function AppBar() {
  const webUrl = BASE_URLS.webUrl;
  const [isVisible, setIsVisible] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [isLoading, setIsLoading] = useState(true);
  const { 
    isLoggedIn,
    setauthCurr, 
    showSignupModal,
    closeSignupModal,
    handleSuccessfulLogin,
    setSuccessfulGoogleLogin,
    openLoginModal,
    openSigninModal,
    handleGoogleLoginSuccess,
    showLoginModal,
    closeLoginModal,
    openSignupModal,
    showSigninModal,
    closeSigninModal,
    handleGoogleLogout,
  } = useAuth();

  const { currDrop, selectedCurrency, setSelectedCurrency } = useCurr();
  const formRef = useRef(null);
  const [Logo, setLogo] = useState([]);
  const mainApiUrl = BASE_URLS.main;

  useEffect(() => {
    const getCurrLogo = async () => {
      try {
        const reslogo = await fetch(`${mainApiUrl}/get_logos.php`);
        if (!reslogo.ok) {
          throw new Error('Failed to fetch logo data');
        }
        const getdatalogo = await reslogo.json();
        setLogo(getdatalogo.main_logo);
      } catch (error) {
        console.error('Error fetching logo data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    getCurrLogo();
  }, [mainApiUrl]);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setIsVisible(winScroll > 450);
  };

  const handleScroll = () => {
    const isMobileScreen = window.innerWidth <= 767;
    const scrollY = window.scrollY;
    setShowComponent(!isMobileScreen || scrollY <= 450);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };

  const handleCurrencyeSelect = (currency) => {
    setSelectedCurrency(currency);
    setauthCurr(currency);
    localStorage.setItem("curr_selected", currency);
  };

 useEffect(() => {
    const handleOutsideClick = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowSearchResults(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowSearchResults(true);
  };

  return (
    <>
      <ToastContainer />
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={isVisible ? 'fixed-top' : ''} 
          bg="light"
          data-bs-theme="light"
        >
          <Container className="header d-flex align-items-center justify-content-between">
          {showComponent && (
              <Navbar.Brand className="logo">
                {isLoading ? (
                  <Skeleton animation="wave" variant="rounded" width={175} height={40} />
                ) : (
                  <Link to={webUrl}>
                    <img
                      src={Logo}
                      className="img-fluid"
                      alt="Rathin Tours LLC"
                      width="130px"
                      height="50px"
                    />
                  </Link>
                )}
              </Navbar.Brand>
            )}
            {isVisible && (
              <div className="hero-sticky mobile-bar">
                  <form
                    ref={formRef}
                    action="#"
                    className="position-relative"
                    onSubmit={handleFormSubmit}
                  >
                    <div className="sign-up-form d-flex">
                      <input
                        name='seach_destination'
                        type="search"
                        className="form-control location-search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onFocus={() => setShowSearchResults(true)}
                        autoComplete="search"
                      />
                      <button type="submit" aria-label="Search Header" className="btn btn-primary">
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                    {showSearchResults && (
                    <SearchResult searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                  )}
                  </form>
              </div>
            )}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                ></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                <Nav.Link as={Link} to={`${webUrl}help-center`}>
                    Help Center
                  </Nav.Link>
                  <NavDropdown
                    title={
                      <span>
                        {selectedCurrency}{" "}
                        <i className="bi bi-chevron-down dropdown-indicator"></i>
                      </span>
                    }
                  >
                  {currDrop.map((item, index) => (
                      <NavDropdown.Item
                        onClick={() => handleCurrencyeSelect(item.country_curr)}
                        key={index}
                      >
                        {item.country_curr} - {item.country_name}
                      </NavDropdown.Item>
                  ))}
                  </NavDropdown>
                  <NavDropdown
                    title={
                      <span>
                        {selectedLanguage}{" "}
                        <i className="bi bi-chevron-down dropdown-indicator"></i>
                      </span>
                    }
                  >
                    <NavDropdown.Item
                      onClick={() => handleLanguageSelect("English")}
                    >
                      English
                    </NavDropdown.Item>
                  </NavDropdown>
                  {isLoggedIn ? (
                    <NavDropdown
                      className="user-profile"
                      title={
                        <span>
                          <i className="bi bi-person-circle text-blue"></i>
                        </span>
                      }
                    >
                      <NavDropdown.Item as={Link} to={`${webUrl}wishlist`}>
                        <i className="bi bi-heart"></i> Wishlist
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={`${webUrl}account/bookings`}
                      >
                        <i className="bi bi-journal-check"></i> Bookings
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={`${webUrl}account/reviews`}
                      >
                        <i className="bi bi-star"></i> Reviews
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={`${webUrl}account/profile`}
                      >
                        <i className="bi bi-person"></i> Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={`${webUrl}account/settings`}
                      >
                        <i className="bi bi-gear"></i> Settings
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleGoogleLogout}>
                        <i className="bi bi-box-arrow-right"></i> Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                                        <>
                      <Nav.Link onClick={openLoginModal}>Login</Nav.Link>
                      <Button variant="primary" onClick={openSignupModal}>
                        Sign up
                      </Button>
                    </>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

      <SignupModal
        showSignup={showSignupModal}
        onHide={closeSignupModal}
        handleSuccessfulLogin={handleSuccessfulLogin}
        setSuccessfulGoogleLogin={setSuccessfulGoogleLogin}
        openLoginModal={openLoginModal}
        openSigninModal={openSigninModal}
        handleGoogleLoginSuccess={handleGoogleLoginSuccess}
      />

      <LoginModal
        showLogin={showLoginModal}
        onHide={closeLoginModal}
        handleSuccessfulLogin={handleSuccessfulLogin} 
        setSuccessfulGoogleLogin={setSuccessfulGoogleLogin}
        openSignupModal={openSignupModal}
        handleGoogleLoginSuccess={handleGoogleLoginSuccess}
      />

      <SigninModal
        showSignin={showSigninModal}
        onHide={closeSigninModal}
        openLoginModal={openLoginModal}
      />
    </>
  );
}

export default AppBar;
