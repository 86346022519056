import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import No from '../Images/404-page.svg';
import { Link } from 'react-router-dom';
import BASE_URLS from '../baseUrl';

export const NotFound = () => {
  const webUrl = BASE_URLS.webUrl;
  return (
    <div className='body-content'>
      <section>
          <Container>
              <Row>
                  <Col className='text-center'>
                      <img src={No} className='img-fluid' width="500px" height="auto" alt="Page Not Found" loading="lazy" />
                      <h1 className='pt-3'>Page Not Found</h1>
                      <p className='pb-3'>The page you are looking for does not exist.</p>
                      <div className='pt-5'>
                        <Link to={webUrl}><Button varian='primary'>Back to Home</Button></Link>
                      </div>
                  </Col>
              </Row>
          </Container>
      </section>
    </div>
  )
}
