import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const BookingList = ({ webUrl, mainApiUrl, selectedCurrency, currConvert, orderComplete }) => {
    const formatReviewDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
        };

        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    };

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderData, setOrderData] = useState(orderComplete);

    const handleShow = (order) => {
        setSelectedOrder(order);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedOrder(null);
        setValidated(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            handleClose();

            try {
                const response = await fetch(`${mainApiUrl}/insert_request_cancellation.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        usr_id: localStorage.getItem("setAuthUser"),
                        req_order_id: selectedOrder.order_id,
                        req_reason: event.target.reqReason.value,
                    }),
                });

                const result = await response.json();

                if (result.status) {
                    toast.success('Cancel request submitted successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    // Update the state with the new data
                    setOrderData((prevOrderData) => {
                        const updatedOrderData = prevOrderData.map((order) => {
                            if (order.order_id === selectedOrder.order_id) {
                                // Update the specific order that was cancelled
                                return {
                                    ...order,
                                    status: 'Cancel Requested',
                                };
                            }
                            return order;
                        });

                        return updatedOrderData;
                    });
                } else {
                    toast.error('Failed to submit cancel request. Please try again.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                console.error('Error submitting request:', error);
            }
        }

        setValidated(true);
    };

    // useEffect(() => {
    //     // Your code to fetch and update data when the component mounts or when certain dependencies change
    //     // This can include the logic to fetch the data from the server

    //     // Example:
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(/* Your API endpoint for fetching data */);
    //             const data = await response.json();
    //             setOrderData(data); // Assuming data is an array of orders
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, [/* Dependencies that trigger a reload, e.g., orderData.length */]);

    return (
        <Row className="list-view">
            <Col md={12}>
                {orderData.map((orderList) => (
                    <div className="wsk-cp-product mb-2" key={orderList.order_id}>
                        <Link to={`${webUrl}account/order-details/${orderList.order_no}`}>
                            <div className="wsk-cp-img">
                                <img loading="lazy" src={orderList.image} alt={orderList.prod_name} className="img-fluid" width="100%" height="auto"/>
                            </div>
                            <div className="wsk-cp-text">
                                <div>
                                    <div className="title-product">
                                        <h3>{orderList.prod_name}</h3>
                                    </div>
                                    <div className="description-prod">
                                        <p className="text-success">Booking Confirmed {formatReviewDate(orderList.booked_date)}</p>
                                        {orderList.status === 'Cancel Requested' ? (
                                            <Link className="mt-3 text-blue gap-1">
                                                <i className="bi bi-hourglass-bottom"></i> Cancel Requested
                                            </Link>
                                        ) : orderList.status === 'Cancelled' ? (
                                            <Link className="mt-3 text-red gap-1">
                                                <i className="bi bi-x-circle"></i> Cancelled
                                            </Link>
                                        ) : (
                                            <Link className="mt-3 text-blue gap-1" onClick={() => handleShow(orderList)}>
                                                <i className="bi bi-send"></i> {orderList.status}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="wcf-left">
                                        Total paid
                                        <span className="price">
                                            <span className="currency">{selectedCurrency}</span>{" "}
                                            {currConvert(orderList.amount)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={handleClose}
                >
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Refund Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Reason *</Form.Label>
                                <Form.Control as="textarea" name="reqReason" maxLength={1000} rows={5} required />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" name="save_changes" variant="primary">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Row>
    );
};
