import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import forgot from '../Images/forgot-password.svg';
import BASE_URLS from '../baseUrl';

export const ForgotPassword = () => {
    const webUrl = BASE_URLS.webUrl;
    const [errorMessage, setErrorMessage] = useState('');
    const [showSecondRow, setShowSecondRow] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const enteredEmail = e.target.email.value;

        // Make a POST request to your PHP endpoint
        try {
            const response = await fetch(`${BASE_URLS.main}/forgot_password.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: enteredEmail }),
            });
    
            if (response.ok) {
                const data = await response.json();
                // Check the response from the server
                if (data.result === 'Success') {
                    setShowSecondRow(true);
                    setErrorMessage('');
                    setSubmittedEmail(enteredEmail);
                } else {
                    setErrorMessage('Email does not match our records.');
                }
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to check email. Please try again.');
        }
    };

    return (
        <div className='body-content'>
            <Helmet>
                <title>Forgot Password | Rathin Tourism LLC</title>
            </Helmet>
            <div className="breadcrumbs">
                <Container>
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li>
                                <Link to={webUrl}>Home</Link>
                            </li>
                            <li>Forgot Password</li>
                        </ol>
                    </div>
                </Container>
            </div>
            <section>
                <Container>
                    {/* If form is not submitted */}
                    {!showSecondRow ? (
                        <Row className="text-center">
                            <Col md={{ span: 4, offset: 4 }}>
                                <h4 className="font-weight-bold">Forgot Your Password?</h4>
                                <p>
                                    Don’t worry. Just tell us the email you used to create your account so we can send you a reset link.
                                </p>
                                <form onSubmit={handleSubmit} className="Login-form">
                                    <div className="form-group mt-3">
                                        <input type="email" name="email" className="form-control" placeholder="Email" required />
                                        <p className="small pt-2 text-muted">Enter the email you used to sign up with us</p>
                                    </div>
                                    <Button type="submit" name='send_button' variant="primary">
                                        Send
                                    </Button>
                                    {errorMessage && <p className="text-danger pt-3">{errorMessage}</p>}
                                </form>
                            </Col>
                        </Row>
                    ) : (
                        <Row className="text-center">
                            <Col md={{ span: 4, offset: 4 }}>
                                <h4 className="font-weight-bold">Check your mail</h4>
                                <img src={forgot} width="250px" height="auto" className="img-fluid pt-3 pb-3" alt="forgot password" loading="lazy"/>
                                <p>
                                    We’ve emailed you instructions for setting your password. If an account exists with <b>{submittedEmail}</b>, you’ll receive
                                    an email containing the password reset link.
                                </p>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section>
        </div>
    );
};
