import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Slider from "react-slick";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import BASE_URLS from '../../baseUrl';
import { Col } from "react-bootstrap";

function OurPartners() {
  const webUrl = BASE_URLS.main;
  const [partner_logo_dts, set_partner_logo_dts] = useState([]);
  const [slidesToShow_no, set_slidesToShow] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${webUrl}/get_footer_useful_links.php?mode=about_us`);
        const getdata = await res.json();
        set_partner_logo_dts(getdata.partner_logo_data_dts);
        set_slidesToShow(getdata.slidesToShow);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [webUrl]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: slidesToShow_no,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }
    ]
  };

   // Hide the entire section if prodData length is zero
   if (partner_logo_dts && partner_logo_dts.length === 0 && !loading) {
    return null;
  }


  return (
    <section className="our-partners">
      <Container>
        <div className="section-header">
          <p>Our Partners & <span>Affiliates</span></p>
        </div>
        <Row className="gy-3">
          {loading ? (
              // Display Skeleton while loading
              Array.from({ length: 6 }).map((_, index) => (
                <Col lg={2} md={3} sm={1} key={index}>
                  <Skeleton variant="rectangular" width={200} height={100} />
                </Col>
              ))
            ) : (
            // Display Partner Logos when data is loaded
            <Slider {...settings}>
              {partner_logo_dts.map((logo) => (
                <div key={logo.partner_logo_id}>
                  <img src={logo.partner_logo} className="img-fluid" width="125px" height="auto" alt={`Partner Logo ${logo.partner_logo_id}`} loading="lazy" />
                </div>
              ))}
            </Slider>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default OurPartners;
