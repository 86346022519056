import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useCurr } from "../../Contexts/currContext";
export const ActivityModal = ({
  showActivity,
  onHide,
  openAvailableDatesModal,
}) => {
const { selectedCurrency, currConvert } = useCurr();
    const handleSelectButtonClick = () => {
        openAvailableDatesModal(); // Call the function to open available dates modal
      };
    
    return (
    <>
        <Modal
        show={showActivity}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
            <Modal.Title>Activity Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="alert alert-info" role="alert">
            <h5>Disneyland® Park and Disney California Adventure® Park</h5>
            <p>
              Disneyland® Resort California - 3 Day Park Hopper with Disney
              Genie+
            </p>
        </div>
        <p>
            Visit Disneyland® Park and Disney California Adventure® Park for
            three days. With a Park Hopper ticket, you may enter the second park
            after 11:00AM, and go between the two parks. Get Lightning Lane
            entrance access with Disney Genie+ for shorter wait times in lines.
            Enjoy augmented-reality effects with Disney PhotoPass Lenses (NEW!),
            engaging audio tales for park exploration, and unlimited Disney
            PhotoPass downloads from the day. Pre-registration is required.
          </p>
            <ul className="list-ul">
                <li>All adults and children will require a ticket for entry</li>
                <li>
              Each single admission ticket admits one participant only. No
              visitor may switch with another person during the specified
              duration.
            </li>
                <li>
              A Single Entry ticket entitles visitors to a one-time entry.
              Re-entries are not allowed after leaving the venue.
            </li>
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <div className="d-grid">
                <span>
              <small>from </small>
            </span>
                <span className="font-weight-bold">
              <big>
                {selectedCurrency} {currConvert(466.5)}
              </big>
            </span>
            </div>
        <Button varaiant="primary" onClick={handleSelectButtonClick}>
            Select
          </Button>
        </Modal.Footer>
        </Modal>
    </>
    );
};
