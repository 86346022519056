import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import BASE_URLS from "../../baseUrl";
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

export const CustomerSays = () => {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;

  const [proDataArr, setproDataArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await fetch(`${mainApiUrl}/get_cust_review.php`);
        const getdata = await res.json();
        if (getdata) {
          setproDataArr(getdata.review_list);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getProduct();
  }, [mainApiUrl]);

  return (
    <section className="testimonials">
      <div className="container">

        <div className="section-header">
          <p>What <span>travellers say</span></p>
        </div>
        <Swiper
          spaceBetween={2}
          freeMode={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          breakpoints={{
            500: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 2,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 2,
            },
          }}
          modules={[Autoplay, FreeMode, Pagination]}
          className="testimonials-slider swiper"
        >
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <Skeleton animation="wave" variant="text" width="100%" height={252} />
                </SwiperSlide>
              ))
            : proDataArr.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonial-item">
                    <p>
                      <i className="fa fa-quote-left quote-icon-left"></i>{item.product_review}<i className="fa fa-quote-right quote-icon-right"></i>
                    </p>
                    <p><Link to={`${webUrl}activity/${item.product_slug}`} title={item.product_name}>{item.product_name}</Link></p>
                    <h3>{item.usr_fname}</h3>
                    <h4>{item.usr_fname_type}</h4>
                    <div className="stars">
                      {[...Array(5)].map((_, index) => (
                        <i key={index} className={index < item.product_rating ? "bi bi-star-fill" : "bi bi-star"}></i>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </section>
  );
};
